.sonr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;

  .sonr {
    font-size: 7em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}