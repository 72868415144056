#app-page {
  .app-description {
    border-radius: 10px;
    padding: 10px;
    list-style-type: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    li {
      margin: 10px 0;
    }

  }

}