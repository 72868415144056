.fading-text-container {
  position: relative;
   width:100%;
   height: 100%;
  .fading-text {
    position: absolute;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  }

}
