@import 'custom';


html {
  height: 100vh;
  background: linear-gradient(to bottom, $primary, #ae3bc2) no-repeat center center fixed;
  background-size: cover;
  white-space: pre-line;

  body {
    height: 100%;
    background-color: transparent;
    color: white;

    a:not(.navbar-brand) {
      color: #eb84ff !important;
    }

    #app {
      @import "MyNavBar";

      #main {
        padding-top: 100px;
      }

    }
  }
}

